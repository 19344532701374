@use 'library' as carousel;
@import 'owl';

.owl-carousel {
  position: relative;

  .owl-nav {
    .owl-prev,
    .owl-next {
      overflow: visible;
      position: absolute;
      height: 100%;
      width: 30px;
      top: 0;
    }

    .owl-prev {
      @include carousel.nav-left;
      left: 0;
    }

    .owl-next {
      @include carousel.nav-right;
      right: -2px;

      &:before {
        right: 2px;
      }
    }
  }
}
