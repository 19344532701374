// старая палитра
$oldColors: (
  grey-50: #f2f2f3,
  grey-100: #dcddde,
  grey-200: #cacbcd,
  grey-300: #b7b9bb,
  grey-400: #adafb2,
  grey-500: #909497,
  grey-600: #7a7e82,
  grey-700: #616365,
  grey-800: #313335,
  grey-900: #1c1c1c,
  grey-basic: #a4a4b0,
  blue-light: #eff0f4,
  blue-medium: #c8ddea,
  blue-dark: #34334b,
  blue-twilight: #f8f8f0,
  blue-cover: #dfe8f4,
  green-light: #cbeac8,
  green-medium: #61c358,
  green-medium-hovered: #49b03f,
  green-dark: #22a017,
  green-yellow: #f1f8e8,
  yellow-light: #f9f7ef,
  yellow-accent: #f4ce64,
  'alabaster': #fafafa,
  'shakespeare': #4aa7d7,
  violet-light: #ced0e2,
  violet-medium: #b8bce0,
  violet-dark: #48496c,
  'red': #fe705d,
  'white': #ffffff,
  'black': #000000,
);

// новая палитра 2024 https://www.figma.com/file/ZaDuOJxUg1PerDXyjnOYjT/!DS-Helix-v.02?type=design&node-id=5977-33948&mode=design
$newColors: (
  green-900: #007041,
  green-800: #00854d,
  green-700: #009959,
  green-600: #00ad64,
  green-basic: #00be6e,
  green-400: #05d67f,
  green-300: #0ce98c,
  green-200: #38f0a3,
  green-100: #7df2c1,
  green-50: #a9f9d8,
  green-30: #d2ffec,
  green-10: #e5fff4,
  'white': #ffffff,
  gray-20: #f6f6f9,
  gray-50: #f0f0f5,
  gray-100: #e8e8ed,
  gray-200: #d7d7db,
  gray-300: #c7c7cc,
  gray-400: #aeaeb2,
  gray-500: #8e8e93,
  gray-600: #636366,
  gray-700: #48484a,
  gray-800: #3a3a3c,
  gray-900: #2a2a2d,
  gray-1000: #1c1c1e,
  'black': #111112,
);

$colors: map-merge(
  $map1: $oldColors,
  $map2: $newColors,
);

@function getColor($color) {
  @return map-get($colors, $color);
}

@function encodeColor($string) {
  @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');
    @return '%23' + $string;
  }
  @return $string;
}
