@use '../palette/library' as palette;
@use '../input/library' as input;
@use '../form/library' as form;
@use '../typography/library' as typography;
@use '../core/library' as core;
@use 'library' as datepicker;
@use '../icon/library' as icon;

.ant-picker {
  .ant-picker-input {
    position: relative;

    input {
      @include input.input;
      @include form.form-control-s;
      @include typography.font-regular;

      width: 100%;
      padding-right: 40px;
      transition-property: none;

      &:disabled {
        @include input.input-disabled;
      }
    }

    .ant-picker-clear {
      content: '';
      position: absolute;
      right: 40px;
      top: calc(50% - 12px);

      > .anticon-close-circle {
        display: none;
      }

      @include input.input-cancel-button;
    }
  }

  &.ant-picker-disabled .ant-picker-suffix::after {
    cursor: default;
    color: palette.getColor(gray-300);
  }

  &.ant-picker-focused {
    input {
      outline: none;
    }
  }

  &.ng-invalid.ng-touched input {
    @include form.form-control-invalid;
  }
}

.ant-picker-wrapper {
  @include datepicker.datepicker-wrapper;

  .ant-picker-header {
    @include datepicker.datepicker-header;

    button {
      min-width: 1.6em;
      background: 0 0;
      border: 0;
      cursor: pointer;
      line-height: 24px;
      padding: 0;
      transition: color 0.3s;
      font-size: 1.125rem;
      color: var(--medium-text-color);

      @include core.hover {
        color: var(--primary-color);
      }
    }

    .ant-picker-header-view {
      @include datepicker.datepicker-header-title;

      button {
        color: var(--text-color);
        font-weight: inherit;
        font-size: 0.875rem;

        @include core.hover {
          color: var(--primary-color);
        }

        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }

    .ant-picker-super-prev-icon {
      @include datepicker.datepicker-header-arrow-double-left;
    }

    .ant-picker-super-next-icon {
      @include datepicker.datepicker-header-arrow-double-right;
    }

    .ant-picker-header-prev-btn {
      @include datepicker.datepicker-header-arrow-left;
    }

    .ant-picker-header-next-btn {
      @include datepicker.datepicker-header-arrow-right;
    }
  }

  table {
    @include datepicker.datepicker-table;

    td {
      cursor: default;

      &:not(.ant-picker-cell-disabled) {
        cursor: pointer;
      }

      &.ant-picker-cell {
        color: datepicker.$cell-color;
        position: relative;
        padding: 1px 0;

        > .ant-picker-cell-inner {
          &.ant-picker-cell-day-with-data {
            color: var(--contrast-text-color-with-bg);
          }
        }

        &.ant-picker-cell-disabled .ant-picker-cell-inner {
          background: none;
          color: datepicker.$cell-disabled-color;

          &.ant-picker-cell-day-with-data::before {
            background-color: unset;
          }
        }

        &.ant-picker-cell-in-view {
          color: datepicker.$cell-in-view-color;
        }

        &.ant-picker-cell-today {
          .ant-picker-cell-inner:before {
            @include datepicker.today-cell;
          }
        }
      }

      &.ant-picker-cell-selected,
      &:hover {
        &:not(.ant-picker-cell-disabled) {
          &:before {
            @include datepicker.selected-cell;
          }

          .ant-picker-cell-inner {
            color: var(--contrast-text-color);
          }
        }
      }
    }
  }

  .ant-picker-month-panel,
  .ant-picker-year-panel,
  .ant-picker-decade-panel {
    table {
      td {
        height: datepicker.$cell-height-big;
        min-width: datepicker.$cell-min-width;

        &.ant-picker-cell {
          &.ant-picker-cell-selected,
          &:hover {
            &:not(.ant-picker-cell-disabled) {
              &::before {
                @include datepicker.selected-cell-big;
                @include datepicker.selected-cell-big-centered;
              }

              .ant-picker-cell-inner {
                color: var(--contrast-text-color);
              }
            }
          }
        }
      }
    }

    .ant-picker-footer {
      display: none;
    }
  }

  .ant-picker-decade-panel {
    table {
      font-size: 0.875rem;
    }
  }

  .ant-picker-cell-day-with-data:before {
    @include datepicker.day-with-data;
  }
}

nz-date-picker .ant-picker-input .ant-picker-suffix {
  > .anticon-calendar {
    display: none;
  }

  &::after {
    @include datepicker.datepicker-right-icon;
    @include icon.glyph-icon(calendar-dates);
  }
}
