@use '../palette/library' as palette;
@use '../shadow/library' as shadow;
@use '../typography/library' as typography;
@use '../icon/library' as icon;
@use '../core/library' as core;

$cell-height: 26px;
$mobile-cell-height: 32px;
$cell-height-big: 48px;
$cell-min-width: $cell-height;

$cell-color: palette.getColor(gray-500);
$cell-in-view-color: var(--text-color);
$cell-disabled-color: palette.getColor(gray-200);

@mixin highlight-cell-base {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: $cell-min-width;
  height: $cell-height;
  line-height: $cell-height;
  content: '';
  border-radius: 100%;

  @include core.md {
    width: $mobile-cell-height;
    height: $mobile-cell-height;
  }
}

@mixin selected-cell {
  @include highlight-cell-base;
  background-color: var(--primary-color);
  z-index: -1;
}

@mixin today-cell {
  @include highlight-cell-base;
  box-shadow: inset 0 0 0 1px var(--primary-color);
  z-index: -2;
}

@mixin day-with-data {
  @include highlight-cell-base;
  background-color: var(--primary-color-light);
  z-index: -3;
}

@mixin selected-cell-big {
  color: var(--contrast-text-color);
  background-color: var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

@mixin selected-cell-big-centered {
  height: 40px;
  width: 80px;
  min-width: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
}

@mixin datepicker-wrapper {
  @include shadow.shadow-white-plus;

  background-color: var(--bg-color);
  border-radius: 10px;
  padding: 16px;
  margin: 6px 12px 6px 0;

  max-width: 280px;

  @include core.xs {
    max-width: 100%;
  }
}

@mixin datepicker-header {
  @include typography.font-bold;

  display: flex;
  justify-content: center;
  padding: 0;
}

@mixin datepicker-header-title {
  @include typography.font-bold;

  flex: auto;
  text-align: center;
  font-size: 0.875rem;
  line-height: 24px;

  @include core.md {
    font-size: 1rem;
  }
}

@mixin datepicker-table {
  display: inline-table;
  transition: none !important;
  table-layout: fixed;
  font-size: 0.875rem;
  width: 100%;
  margin: 0;
  border-spacing: 0;

  @include core.md {
    font-size: 1rem;
  }

  td,
  th {
    @include typography.font-regular;

    width: auto;
    text-align: center;
    line-height: $cell-height;
    padding: 0;
    position: relative;

    @include core.md {
      height: $mobile-cell-height;
      line-height: $mobile-cell-height;
    }
  }

  td,
  td > * {
    z-index: 1;
  }

  th {
    font-size: 0.875rem;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  thead {
    color: $cell-color;
  }
}

@mixin datepicker-right-icon {
  position: absolute;
  font-size: 24px;
  color: var(--medium-text-color);
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@mixin datepicker-header-arrow-left {
  left: 0;

  &::before {
    @include icon.glyph-icon(chevron-left);
  }
}

@mixin datepicker-header-arrow-right {
  right: 0;

  &::before {
    @include icon.glyph-icon(chevron-right);
  }
}

@mixin datepicker-header-arrow-double-left {
  &::before {
    display: inline-block;
    transform: rotate(180deg);

    @include icon.glyph-icon(chevron-double);
  }
}

@mixin datepicker-header-arrow-double-right {
  &::before {
    @include icon.glyph-icon(chevron-double);
  }
}
