@use 'sass:math';
@use '../sizes/library' as sizes;
@use '../core/library' as core;

@each $size in sizes.$sizes {
  @include core.responsive(1, 0.875, 0.75, 0.625, 0.5) using ($d) {
    $negativeOffset: calc(-#{$size}px / 2 * #{$d});
    $positiveOffset: calc(#{$size}px / 2 * #{$d});
    %margin-x-minus-half-#{$size} {
      margin-left: $negativeOffset;
      margin-right: $negativeOffset;
    }
    %margin-y-minus-half-#{$size} {
      margin-top: $negativeOffset;
      margin-bottom: $negativeOffset;
    }
    %padding-x-half-#{$size} {
      padding-left: $positiveOffset;
      padding-right: $positiveOffset;
    }
    %padding-y-half-#{$size} {
      padding-top: $positiveOffset;
      padding-bottom: $positiveOffset;
    }
  }
}

.grid {
  display: flex;

  &.grid-column {
    flex-direction: column;
  }

  &.justify-content-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-start {
    justify-content: flex-start;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }

  &.align-items-center {
    align-items: center;
  }
  &.align-items-start {
    align-items: flex-start;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.align-items-baseline {
    align-items: baseline;
  }

  &.align-content-between {
    align-content: space-between;
  }
  &.align-content-center {
    align-content: center;
  }

  &.grid-nowrap {
    flex-wrap: nowrap;
  }

  .grid-grow {
    flex-grow: 1;
    flex-basis: 0;
  }

  @each $key, $value in core.$breakpoints {
    &.grid-#{$key}-column {
      @include core.breakpoint($key) {
        flex-direction: column;
      }
    }

    &.justify-content-#{$key}-between {
      @include core.breakpoint($key) {
        justify-content: space-between;
      }
    }
    &.justify-content-#{$key}-center {
      @include core.breakpoint($key) {
        justify-content: center;
      }
    }
    &.justify-content-#{$key}-start {
      @include core.breakpoint($key) {
        justify-content: flex-start;
      }
    }
    &.justify-content-#{$key}-end {
      @include core.breakpoint($key) {
        justify-content: flex-end;
      }
    }

    &.align-items-#{$key}-center {
      @include core.breakpoint($key) {
        align-items: center;
      }
    }
    &.align-items-#{$key}-start {
      @include core.breakpoint($key) {
        align-items: flex-start;
      }
    }
    &.align-items-#{$key}-end {
      @include core.breakpoint($key) {
        align-items: flex-end;
      }
    }

    &.align-content-#{$key}-between {
      @include core.breakpoint($key) {
        align-content: space-between;
      }
    }

    &.grid-#{$key}-nowrap {
      @include core.breakpoint($key) {
        flex-wrap: nowrap;
      }
    }
    &.grid-#{$key}-wrap {
      @include core.breakpoint($key) {
        flex-wrap: wrap;
      }
    }

    .grid-#{$key}-grow {
      @include core.breakpoint($key) {
        flex-grow: 1;
        flex-basis: 0;
      }
    }

    .grid-#{$key}-nogrow {
      @include core.breakpoint($key) {
        flex-grow: 0;
        flex-basis: auto;
      }
    }
  }

  flex-wrap: wrap;

  @each $size in sizes.$sizes {
    &.grid-gutter-#{$size} {
      @extend %margin-x-minus-half-#{$size};

      > .grid-item {
        @extend %padding-x-half-#{$size};
      }
    }

    &.grid-gutter-#{$size}:not(.grid-nowrap) {
      @extend %margin-y-minus-half-#{$size};

      > .grid-item {
        @extend %padding-y-half-#{$size};
      }
    }
  }

  .grid-item {
    // https://material-ui.com/components/grid/#white-space-nowrap
    &.zero-min-width {
      min-width: 0;
    }

    @for $i from 1 through 12 {
      &.grid-#{$i} {
        flex: 0 0 math.percentage(calc($i/12));
        max-width: math.percentage(calc($i/12));
      }
    }

    @for $i from 1 through 12 {
      &.grid-lg-#{$i} {
        @include core.lg {
          flex: 0 0 math.percentage(calc($i/12));
          max-width: math.percentage(calc($i/12));
        }
      }
    }

    @for $i from 1 through 12 {
      &.grid-md-#{$i} {
        @include core.md {
          flex: 0 0 math.percentage(calc($i/12));
          max-width: math.percentage(calc($i/12));
        }
      }
    }

    @for $i from 1 through 12 {
      &.grid-sm-#{$i} {
        @include core.sm {
          flex: 0 0 math.percentage(calc($i/12));
          max-width: math.percentage(calc($i/12));
        }
      }
    }

    @for $i from 1 through 12 {
      &.grid-xs-#{$i} {
        @include core.xs {
          flex: 0 0 math.percentage(calc($i/12));
          max-width: math.percentage(calc($i/12));
        }
      }
    }

    &.no-shrink {
      flex-shrink: 0;
    }
  }
}
