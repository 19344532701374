@use 'library' as button;
@use '../form/library' as form;
@use '../core/library' as core;
@use '../icon/library' as icon;

.button {
  @include button.button;
  @include button.button-primary;
  @include button.button-s;

  &.button-secondary {
    @include button.button-secondary;
  }

  &.button-subtle {
    @include button.button-subtle;

    &.button-bordered {
      @include button.button-subtle-bordered;
    }
  }

  &.button-vi {
    @include button.button-vi;
  }

  &.button-xs {
    $size: 26px;

    min-height: $size;
    font-size: var(--button-xs-text-size);
    line-height: var(--button-xs-text-size);
    padding: calc((#{$size} - 2px - var(--button-xs-text-size)) / 2);
    min-width: $size;
    .icon {
      font-size: var(--button-xs-text-size);
      margin-right: 0;
    }
  }

  &.button-m {
    @include form.form-control-m;
  }

  &.button-l {
    @include form.form-control-l;
  }

  &.loading {
    &::after {
      display: inline-block;
      position: absolute;
      font-size: 16px;
      line-height: 16px;
      left: calc(50% - 8px);
      top: calc(50% - 8px);

      @include icon.glyph-icon(spinner);
      @include core.spin;
    }
  }
}
