@use 'library' as markerlist;

.markerlist {
  list-style: none;
  margin: 0;

  &:not(.markerlist-l) {
    padding: 0 0 0 1.6em;

    > li {
      position: relative;
      margin: 0.6em 0;
      padding-left: 8px;

      &::before {
        position: absolute;
        width: 1.2em;
        font-size: 1.2em;
        text-align: center;
        right: 100%;
      }

      @include markerlist.markerlist-bullet-icon;
    }

    &.markerlist-check {
      > li {
        @include markerlist.markerlist-check-icon;
      }
    }
  }

  &.markerlist-l {
    padding: 0 0 0 52px;

    > li {
      @include markerlist.markerlist-check-icon-l;
      position: relative;
      margin: 1.6em 0;
      padding-left: 8px;

      &::before {
        position: absolute;
        right: calc(100% + 4px);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &.markerlist-hyphen {
    > li {
      @include markerlist.marketlist-hyphen-icon;

      &::before {
        font-size: 1em;
      }
    }
  }
}
