@use '../palette/library' as palette;
@use '../core/library' as core;

ul,
ol {
  text-align: left !important;
}

ul {
  @include core.responsive(30px, 30px, 24px, 24px, 16px) using ($d) {
    padding-inline-start: $d;
  }

  li {
    padding-left: 10px;
    background: none !important;

    &::marker {
      content: core.unicode('25CF');
      color: palette.getColor(violet-light);
    }
  }
}

ol {
  @include core.responsive(40px, 40px, 34px, 34px, 26px) using ($d) {
    padding-inline-start: $d;
  }
}

ul,
ol {
  margin: 0 !important;
  li {
    margin: 10px 0 !important;
  }
}
