@use '../form/library' as form;
@use '../palette/library' as palette;
@use './library' as select;

@mixin select-array-icon($color) {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.293 7.29289L20.7072 8.70711L12.0001 17.4142L3.29297 8.70711L4.70718 7.29289L12.0001 14.5858L19.293 7.29289Z' fill='" + $color + "'/%3E%3C/svg%3E%0A");
}

.select {
  @include select.select;
  @include form.form-control-s;
  @include form.form-control-s-right-icon;
  @include select-array-icon(palette.encodeColor(palette.getColor(gray-1000)));

  @at-root .visually-impaired & {
    @include select-array-icon(palette.encodeColor(black));
  }

  @at-root .visually-impaired-black & {
    @include select-array-icon(palette.encodeColor(white));
  }

  @at-root .visually-impaired-brown & {
    @include select-array-icon(palette.encodeColor(#a9e44d));
  }

  display: inline-flex;
  align-items: center;
  appearance: none;

  &::-ms-expand {
    display: none;
  }

  & > .icon {
    margin-right: 10px;
  }
}

.multiselect-count {
  margin-left: 10px;
}

.multiselect-item {
  .checkbox {
    margin-right: 10px;
  }
}

@import 'ant';
