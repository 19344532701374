@use '../core/library' as core;
@use '../palette/library' as palette;
@use '../shadow/library' as shadow;
@use '../icon/library' as icon;

// https://mobile.ant.design/components/floating-bubble

.floating-button {
  cursor: pointer;
  position: fixed;
  right: 18px;
  z-index: 9999;
  display: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: palette.getColor(violet-dark);
  box-shadow: shadow.getShadow('white');

  &::after {
    @include icon.glyph-icon(filter);

    position: absolute;
    font-size: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--contrast-text-color);
  }

  @include core.sm {
    display: block;
    top: 116px;
  }

  @include core.xs {
    top: 156px;
  }

  &-with-indicator::before {
    content: '';
    border-radius: 50%;
    width: 18px;
    height: 18px;
    background-color: palette.getColor(green-basic);
    position: absolute;
    bottom: 0;
    right: 0;

    @at-root .visually-impaired & {
      background-color: var(--primary-color);
      border: 1px solid var(--contrast-text-color);
    }
  }

  @at-root .visually-impaired & {
    background-color: var(--primary-color);
  }
}
