@use '../palette/library' as palette;
@use '../typography/library' as typography;
@use 'sass:map';

$statuses: (404, 500);

nz-result {
  display: block;
}

.ant-result {
  padding: 80px 32px;

  .ant-result-icon {
    margin-bottom: 40px;
    text-align: center;
  }

  @each $status in $statuses {
    &.result-status-#{$status} {
      .ant-result-icon {
        & > * > svg {
          & > * {
            display: none;
          }

          width: 128px;
          height: 128px;
          background-image: url('./#{$status}.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.ant-result-title {
  @include typography.typography(
    map.get(typography.$typography-variants, hero)...
  );
  font-weight: map.get(typography.$font-weights, bold);
  text-align: center;
  color: var(--text-color);
}

.ant-result-subtitle {
  @include typography.typography(
    map.get(typography.$typography-variants, headline)...
  );
  margin-top: 16px;
  text-align: center;
  color: var(--tabs-text-color);
}

.ant-result-extra {
  margin: 36px 0 0 0;
  text-align: center;

  & > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}
