@use '../form/library' as form;
@use '../dropdown/library' as dropdown;
@use '../palette/library' as palette;
@use './library' as select;
@use '../core/library' as core;
@use '../icon/library' as icon;

.ant-select {
  outline: none;
  position: relative;
  display: inline-flex;

  &.ng-invalid.ng-touched .ant-select-selector {
    @include form.form-control-invalid;
  }

  .ant-select-selector {
    @include select.select;
    @include form.form-control-s;

    width: 100%;
    padding-right: 44px;

    &::after {
      @include icon.glyph-icon(chevron-down);
      @include select.select-right-icon;
    }

    input {
      display: none;
      font-size: 1rem;

      &.ant-select-selection-search-input {
        cursor: auto;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: 0 0;
        border: none;
        margin: 0;
        outline: none;
        padding: 0;
        height: 100%;
        display: block;
        width: 100%;
        color: var(--text-color);
      }
    }

    .ant-select-selection-placeholder {
      color: palette.getColor(gray-500);
    }
  }

  &.ant-select-single {
    .ant-select-selector {
      .ant-select-selection-search {
        position: absolute;
        top: 0;
        right: 40px;
        bottom: 0;
        left: 16px;
      }
    }

    &.ant-select-open {
      .ant-select-selection-item {
        color: palette.getColor(gray-500);
      }
    }
  }

  &.ant-select-disabled {
    .ant-select-selector {
      background: palette.getColor(gray-50);
      cursor: not-allowed;
    }
  }

  &.loading {
    .ant-select-selector {
      &::after {
        @include icon.glyph-icon(spinner);
        @include select.select-right-icon;
        @include core.spin;
      }
    }
  }

  .ant-select-clear {
    .ant-select-close-icon {
      @include select.select-clear;

      display: none;

      & > svg {
        display: none;
      }
    }
  }

  .ant-select-arrow {
    display: none;
  }

  &:hover {
    .ant-select-clear {
      .ant-select-close-icon {
        display: flex;
      }
    }
  }
}

.ant-select-dropdown {
  display: block;
  margin-top: dropdown.$dropdown-gutter;
  padding: 16px;
  @include dropdown.dropdown-paper;

  .ant-select-item {
    @include dropdown.dropdown-item;

    &.ant-select-item-option-selected {
      @include select.select-dropdown-item-selected;
    }

    &.ant-select-item-option-disabled {
      @include select.select-dropdown-item-disabled;
    }
  }

  .full-width {
    contain: initial;

    .cdk-virtual-scroll-content-wrapper {
      position: static;
    }
  }
}
