@use 'library' as tabs;
@use '../core/library' as core;
@use '../palette/library' as palette;

.ant-tabs {
  .ant-tabs-nav {
    flex: none;
  }

  .ant-tabs-content-holder {
    flex: auto;
    width: 100%; // IE
  }

  .ant-tabs-nav-wrap {
    display: inline-flex;
    overflow: hidden;
    width: 100%;
    position: relative;

    .ant-tabs-nav-list {
      display: flex;
    }

    .ant-tabs-tab {
      @include tabs.tabs-tab;

      &.ant-tabs-tab-active {
        @include tabs.tabs-tab-active;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    &.ant-tabs-nav-wrap-ping-right::after {
      @include core.scroll-gradient-right;
      @include core.white-gradient;
    }
  }

  &.tabs-nav-gray {
    .ant-tabs-tab {
      &.ant-tabs-tab-active {
        color: var(--button-contrast-color);

        &::after {
          background: var(--button-contrast-color);
        }
      }

      @at-root .visually-impaired & {
        color: var(--contrast-text-color-with-bg);
      }
    }

    .ant-tabs-nav-wrap {
      @include tabs.tabs-nav-gray;
      position: relative;

      &.ant-tabs-nav-wrap-ping-right::after {
        @include core.scroll-gradient-right;
        @include core.gray-gradient;
      }
    }

    .ant-tabs-nav-list {
      padding: 0 16px;
    }
  }

  .ant-tabs-content {
    @include tabs.tabs-content;
    display: flex;
    width: 100%;

    .ant-tabs-tabpane {
      width: 100%;
      flex: none;
    }
  }

  .ant-tabs-nav-operations {
    display: none;
  }

  // vertical
  &.ant-tabs-right,
  &.ant-tabs-left {
    display: flex;

    .ant-tabs-nav-wrap {
      overflow: visible;

      .ant-tabs-nav-list {
        @include tabs.tabs-nav-vertical;

        .ant-tabs-tab {
          @include tabs.tabs-tab-vertical;

          margin-left: unset;

          &:not(:first-child) {
            margin-top: 8px;
          }

          &:not(.ant-tabs-tab-active) {
            @include tabs.tabs-tab-vertical-hover;
          }
        }
      }
    }

    .ant-tabs-content {
      @include tabs.tabs-content-vertical;
    }
  }

  &.ant-tabs-right {
    .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
      @include tabs.tabs-tab-vertical-active-icon-left;
    }

    .ant-tabs-content .ant-tabs-tabpane {
      padding-right: 42px;
    }

    .ant-tabs-nav {
      order: 1;
    }

    .ant-tabs-content-holder {
      order: 0;
    }
  }

  &.ant-tabs-left {
    .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
      @include tabs.tabs-tab-vertical-active-icon-right;
    }

    .ant-tabs-content .ant-tabs-tabpane {
      padding-left: 42px;
    }
  }

  // extra
  &.ant-tabs-right,
  &.ant-tabs-left {
    &.extra-first {
      .ant-tabs-nav {
        display: flex;
        flex-direction: column;

        .ant-tabs-extra-content {
          order: 0;
        }

        .ant-tabs-nav-wrap {
          order: 1;
        }
      }
    }
  }
}
