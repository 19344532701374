@use '../dropdown/library' as dropdown;

$popover-gutter: 8px;

.ant-popover {
  .ant-popover-inner {
    @include dropdown.dropdown-paper;
  }

  &.ant-popover-placement-top,
  &.ant-popover-placement-topLeft,
  &.ant-popover-placement-topRight {
    padding-bottom: $popover-gutter;
  }

  &.ant-popover-placement-right,
  &.ant-popover-placement-rightTop,
  &.ant-popover-placement-rightBottom {
    padding-left: $popover-gutter;
  }

  &.ant-popover-placement-left,
  &.ant-popover-placement-leftTop,
  &.ant-popover-placement-leftBottom {
    padding-right: $popover-gutter;
  }

  &.ant-popover-placement-bottom,
  &.ant-popover-placement-bottomLeft,
  &.ant-popover-placement-bottomRight {
    padding-top: $popover-gutter;
  }
}
