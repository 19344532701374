@use '../palette/library' as palette;
@use '../core/library' as core;
@use 'sass:color';

a,
.link {
  text-decoration: none;
  color: inherit;

  @at-root .typography &,
    &.typography {
    cursor: pointer;
    @include core.transition;

    @include core.hover {
      color: color.scale(palette.getColor(gray-1000), $lightness: 40%);
    }

    @each $key, $value in palette.$colors {
      &.#{$key} {
        @include core.hover {
          @if lightness($value) < 30% {
            color: color.scale($value, $lightness: 40%);
          } @else {
            color: color.scale($value, $lightness: -20%);
          }
        }
      }
    }
  }
}
