@use '../form/library' as form;
@use 'library' as input;
@use '../typography/library' as typography;
@use '../core/library' as core;
@use '../palette/library' as palette;
@use 'ant';

@mixin input-search-icon($color) {
  background-image: url("data:image/svg+xml;utf-8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.45206' cy='7.45194' r='6.15604' stroke='" + $color + "'/%3E%3Cpath d='M11.9881 11.988L14.2561 14.2561' stroke='" + $color + "' stroke-width='1.45801' stroke-linecap='round'/%3E%3C/svg%3E");
}

@mixin input-search-specific {
  &:not(.input-grey) {
    padding-top: 9px;
    padding-bottom: 9px;
    min-height: 40px;
    line-height: 18px;
    font-size: 14px;
  }
}

.input {
  @include input.input;
  @include form.form-control-s;
  @include typography.font-regular;
  @include core.hover {
    border-color: palette.getColor(green-basic);
  }

  &.input-search {
    @include form.form-control-s-left-icon;

    @include input-search-icon(palette.encodeColor(palette.getColor(gray-600)));

    border: 1px solid palette.getColor(gray-100);
    border-radius: 8px;

    @include input-search-specific();

    @include core.hover {
      border: 1px solid palette.getColor(gray-400);
    }

    &.input-cancel-button::-webkit-search-cancel-button {
      -webkit-appearance: none;

      @include input.input-cancel-button;
    }

    &.input-gray {
      @include form.form-control-s-right-icon;

      padding-left: 16px;
      border: 2px solid var(--input-bd-color);
      background-color: var(--input-bg-color-disabled);
      @include input-search-icon(
        palette.encodeColor(palette.getColor(gray-400))
      );

      @include core.hover {
        border: 2px solid var(--input-bd-color);
        @include input-search-icon(
          palette.encodeColor(palette.getColor(gray-400))
        );
      }
    }

    @at-root .visually-impaired & {
      @include input-search-icon(palette.encodeColor(black));

      border-color: var(--input-bd-color);
    }

    @at-root .visually-impaired-black & {
      @include input-search-icon(palette.encodeColor(white));
    }

    @at-root .visually-impaired-brown & {
      @include input-search-icon(palette.encodeColor(#a9e44d));
    }
  }

  &:disabled {
    @include input.input-disabled;
  }
}
