@use '../palette/library' as palette;

@mixin tag-gray {
  color: palette.getColor(gray-800);
  background-color: palette.getColor(gray-50);
  border-color: palette.getColor(gray-50);
}

@mixin tag-violet {
  color: var(--tag-active-text);
  background-color: var(--tag-active-bg);
  border-color: var(--tag-active-bg);
}

@mixin tag-white {
  color: palette.getColor('black');
  background-color: palette.getColor('white');
}

@mixin tag-s {
  font-size: 0.75rem;
  padding: 4px 6px;
  border-radius: 4px;
}

@mixin tag-m {
  font-size: 0.75rem;
  padding: 8px;
  border-radius: 8px;
}

@mixin tag-l {
  line-height: 20px;
  font-size: 0.875rem;
  padding: 10px 12px;
  border-radius: 8px;
}

@mixin tag-bordered {
  border: 2px solid palette.getColor(gray-200);
  border-radius: 10px;
}

@mixin active {
  color: var(--tag-active-text);
  background-color: var(--tag-active-bg);
}
