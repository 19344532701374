@use '../palette/library' as palette;
@use '../core/library' as core;
@use 'sass:color';

a {
  transition: all 0.15s ease-in;
  text-decoration: none;
  color: palette.getColor(green-basic);

  @include core.hover {
    color: color.scale(palette.getColor(green-basic), $lightness: -20%);
  }
}
