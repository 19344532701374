@use '../palette/library' as palette;

.checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 24px;
  height: 24px;
  background-color: palette.getColor('white');
  border: 1px solid var(--input-bd-color);
  border-radius: var(--bd-radius);
}

/* Hide the browser's default checkbox */
.checkbox .checkbox-input {
  display: none;
}

/* Create a custom checkbox */
.checkbox-mark {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-mark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox .checkbox-input:checked ~ .checkbox-mark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkbox-mark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid palette.getColor(gray-1000);
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
}
