@use '../palette/library' as palette;

$steps-item-number-size: 44px;
$steps-item-number-top-offset: 8px;
$steps-item-icon-size: 128px;

@mixin steps-item-number {
  background: linear-gradient(#33cb8b, #00be6e);
  color: var(--contrast-text-color);
  text-align: center;

  @at-root .visually-impaired & {
    background: var(--primary-color);
  }
}

@mixin steps-item-number-size($size) {
  width: $size;
  height: $size;
  line-height: $size;
  border-radius: calc($size / 2);
}
