@use 'sass:map';
@use '../core/library' as core;
@use '../typography/library' as typography;

p {
  margin: 0 !important;
  margin-bottom: 1em !important;
}

@mixin markup-typography($xl, $md, $xs, $line-height) {
  @include core.responsive($xl, $xl, $md, $md, $xs) using ($d) {
    font-size: $d;
  }

  line-height: $line-height;
}

& {
  color: var(--text-color);

  @include markup-typography(0.875rem, 0.875rem, 0.875rem, 140%);

  &.html-markup-l {
    @include markup-typography(1rem, 1rem, 1rem, 140%);
  }

  &.html-markup-strong-heading {
    strong {
      color: var(--text-color);
    }
  }
}

strong {
  @include typography.font-bold;
}

h2,
h3,
h4 {
  margin: 0 !important;
  margin-bottom: 1em !important;

  color: var(--text-color);
  font-weight: normal !important;

  * {
    font-weight: inherit !important;
    font-family: inherit !important;
  }
}

h2 {
  margin-top: 1.5em !important;
  font-size: 1.75rem !important;
}

h3 {
  font-size: 1.5rem !important;
}

h4 {
  font-size: 1.25rem !important;
}
