@use '../palette/library' as palette;

$switch-width: 45px;
$switch-height: 24px;
$slider-size: $switch-height - 4px;
$border-size: 1px;
$padding: 1px;

.switch {
  .switch-input {
    display: none;

    &:checked {
      + .switch-track {
        background: palette.getColor(green-30);
        border: $border-size solid palette.getColor(green-30);

        &::after {
          background: var(--primary-color);
          transform: translateX(
            calc($switch-width - $slider-size - ($padding + $border-size) * 2)
          );
        }
      }
    }
  }

  .switch-track {
    cursor: pointer;
    width: $switch-width;
    height: $switch-height;
    background: palette.getColor(gray-50);
    border: $border-size solid palette.getColor(gray-50);
    display: block;
    border-radius: calc($switch-height / 2);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: $padding;
      width: $slider-size;
      height: $slider-size;
      background: palette.getColor(gray-300);
      border-radius: calc($slider-size / 2);
      transition: 0.3s;
    }
  }

  &.switch-subtle {
    .switch-track,
    .switch-input:checked + .switch-track {
      background: var(--bg-color);
      border: $border-size solid var(--bg-color);
    }
  }

  @at-root .visually-impaired & {
    .switch-track,
    .switch-input:checked + .switch-track {
      background: var(--bg-color);
      border: $border-size solid var(--primary-color);
    }
  }
}
