@use '../palette/library' as palette;
@use '../core/library' as core;
@use 'sass:math';

@mixin form-control {
  border-radius: var(--bd-radius);
}

@mixin form-control-size($height, $line-height, $border-width, $px) {
  $py: math.div($height - $line-height - 2 * $border-width, 2);

  min-height: $height;
  line-height: $line-height;
  padding: {
    top: $py;
    bottom: $py;
    left: $px;
    right: $px;
  }
}

@mixin form-control-s {
  @include form-control;
  @include form-control-size(44px, 22px, 1, 16px);
  font-size: 1rem;
  min-width: 80px;
}

@mixin form-control-s-left-icon {
  padding-left: 40px;
  background-repeat: no-repeat;
  background-position: 12px;
  background-size: 16px;
}

@mixin form-control-s-right-icon {
  padding-right: 40px;
  background-repeat: no-repeat;
  background-position: calc(100% - 14px);
  background-size: 20px;
}

@mixin form-control-m {
  @include form-control;
  @include form-control-size(52px, 22px, 1, 16px);
  font-size: 0.875rem;
}

@mixin form-control-l {
  @include form-control;
  @include form-control-size(68px, 26px, 1, 34px);
  font-size: 1rem;
}

@mixin form-control-invalid {
  @include core.hover {
    border: 1px solid palette.getColor('red');
    color: palette.getColor('red');
  }
  border: 1px solid palette.getColor('red');
  color: palette.getColor('red');
}
