@use '../typography/library' as typography;
@use '../palette/library' as palette;

@mixin textarea-placeholder {
  color: palette.getColor(gray-300);
  opacity: 1;
}

.textarea {
  @include typography.font-regular;

  &::placeholder {
    @include textarea-placeholder;
  }
  &:-ms-input-placeholder {
    @include textarea-placeholder;
  }

  color: var(--text-color);

  &_no-resize {
    resize: none;
  }

  &_vertical-resize {
    resize: vertical;
  }
}
