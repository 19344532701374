@use '../palette/library' as palette;
@use '../shadow/library' as shadow;

.buttongroup {
  display: flex;
  .buttongroup-button {
    color: var(--text-color);
    cursor: pointer;
    border-radius: var(--bd-radius);
    font-size: 1rem;
    line-height: 24px;
    border: 1px solid var(--input-bd-color);
    padding: 7px 12px;

    &:not(:first-child) {
      margin-left: 10px;
    }

    &.buttongroup-button-active {
      border: 1px solid var(--button-contrast-color);
      color: var(--button-contrast-color);
      background-color: var(--button-subtle-bd-color);
      @include shadow.shadow-white-plus;
    }
  }

  &.disabled {
    .buttongroup-button {
      pointer-events: none;
      border-color: palette.getColor(gray-200);
      color: palette.getColor(gray-500);
      background-color: var(--input-bg-color-disabled);

      &.buttongroup-button-active {
        border-color: palette.getColor(green-100);
        color: palette.getColor(green-100);
        background-color: palette.getColor('white');
        box-shadow: none;

        @at-root .visually-impaired & {
          border-color: palette.getColor(gray-300);
          color: var(--contrast-text-color);
          background-color: palette.getColor(gray-300);
        }
      }
    }
  }
}
