@use 'library' as image;
@use 'ant';

.image-container {
  position: relative;

  .image {
    position: absolute;
    right: 0;
    height: 100%;

    &.image-responsive {
      position: static;
      width: 100%;
      height: auto;
    }
  }

  &.image-zoom-icon {
    @include image.zoom-icon;
  }
}
