$shadows: (
  'green': 0px 4px 8px rgba(0, 190, 110, 0.16),
  'white': 0px 6px 14px rgba(28, 28, 30, 0.06),
  white-plus: 0px 4px 14px rgba(28, 28, 30, 0.1),
  white-plus-top: 0px -4px 14px rgba(28, 28, 30, 0.1),
);

@function getShadow($shadow) {
  @return map-get($shadows, $shadow);
}

@mixin shadow-green {
  box-shadow: getShadow('green');
}

@mixin shadow-white {
  box-shadow: getShadow('white');
}

@mixin shadow-white-plus {
  box-shadow: getShadow(white-plus);
}

@mixin shadow-white-plus-top {
  box-shadow: getShadow(white-plus-top);
}
