@use '../palette/library' as palette;
@use 'library' as typography;
@use 'sass:map';
@use '../core/library' as core;

html {
  font-size: var(--body-font-size);
  font-family: Golos, sans-serif;
}

body {
  font-size: 0;
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

@each $name, $font-weight in typography.$font-weights {
  @font-face {
    font-display: swap;
    font-family: Golos;
    src:
      local('GolosText-#{core.capitalize($name)}'),
      url(./fonts/Golos/#{$name}.woff2) format('woff2'),
      url(./fonts/Golos/#{$name}.woff) format('woff');
    font-weight: $font-weight;
  }
}

.typography {
  $arg: map.get(typography.$typography-variants, body);
  @include typography.typography($arg...);

  @each $name, $params in typography.$typography-variants {
    &.typography-#{$name} {
      @include typography.typography($params...);
    }
  }

  font-weight: map.get(typography.$font-weights, regular);

  &.typography-medium {
    font-weight: map.get(typography.$font-weights, medium);
  }

  &.typography-bold {
    font-weight: map.get(typography.$font-weights, bold);
  }

  &.typography-paragraph {
    @include core.responsive(1em, 1em, 0.85em, 0.7em, 0.7em) using ($d) {
      margin-bottom: $d !important;
    }
  }

  &.typography-subheadline {
    text-transform: uppercase;
  }

  &.typography-centered, // Obsolete
  &.typography-x-center, // Obsolete
  &.typography-align-center {
    text-align: center;
  }

  &.typography-x-end, // Obsolete
  &.typography-align-end {
    text-align: end;
  }

  @each $key, $value in core.$breakpoints {
    &.typography-align-#{$key}-center {
      @include core.breakpoint($key) {
        text-align: center;
      }
    }
    &.typography-align-#{$key}-start {
      @include core.breakpoint($key) {
        text-align: start;
      }
    }
    &.typography-align-#{$key}-end {
      @include core.breakpoint($key) {
        text-align: end;
      }
    }
  }

  &.typography-underline {
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &.typography-h1,
    &.typography-h2,
    &.typography-h3,
    &.typography-h4 {
      border-bottom: 2px solid var(--primary-color);
      padding-bottom: 6px;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
