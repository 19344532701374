@use 'library' as pageheader;

.breadcrumbs {
  @include pageheader.breadcrumbs;
  .breadcrumbs-item {
    @include pageheader.breadcrumbs-item;
  }
}

.pageheader {
  @include pageheader.pageheader;
}
.pageheader-title {
  @include pageheader.pageheader-title;
  .pageheader-back-button {
    @include pageheader.pageheader-title-back-button;
  }
}
