@use '../core/library' as core;

@mixin card-s {
  border-radius: 10px;
  @include core.sm {
    border-radius: 8px;
  }

  > .card-header {
    @include core.responsive(
        16px 32px,
        12px 24px,
        12px 24px,
        10px 20px,
        8px 16px
      )
      using ($d) {
      padding: $d;
    }
  }

  > .card-body {
    @include core.responsive(
        16px 32px,
        12px 24px,
        12px 24px,
        10px 20px,
        8px 16px
      )
      using ($d) {
      padding: $d;
    }
  }

  > .card-footer {
    @include core.responsive(
        16px 32px,
        12px 24px,
        12px 24px,
        10px 20px,
        8px 16px
      )
      using ($d) {
      padding: $d;
    }
  }

  &.card-dense {
    > .card-body {
      @include core.responsive(16px, 14px, 12px, 10px, 8px) using ($d) {
        padding-left: $d;
        padding-right: $d;
      }
    }
  }
}

@mixin card-m {
  @include core.responsive(20px, 20px, 16px, 12px, 8px) using ($d) {
    border-radius: $d;
  }

  > .card-header {
    @include core.responsive(
        24px 36px,
        20px 32px,
        20px 32px,
        16px 28px,
        12px 18px
      )
      using ($d) {
      padding: $d;
    }
  }

  > .card-body {
    @include core.responsive(
        24px 36px,
        20px 32px,
        20px 32px,
        16px 28px,
        12px 18px
      )
      using ($d) {
      padding: $d;
    }
  }

  > .card-footer {
    @include core.responsive(
        24px 36px,
        20px 32px,
        20px 32px,
        16px 28px,
        12px 18px
      )
      using ($d) {
      padding: $d;
    }
  }
}

@mixin card-l {
  @include core.responsive(20px, 20px, 16px, 12px, 8px) using ($d) {
    border-radius: $d;
  }

  > .card-header {
    @include core.responsive(52px, 46px, 36px, 24px, 20px) using ($d) {
      padding: $d;
    }
  }

  > .card-body {
    @include core.responsive(52px, 46px, 36px, 24px, 20px) using ($d) {
      padding: $d;
    }
  }

  > .card-footer {
    @include core.responsive(52px, 46px, 36px, 24px, 20px) using ($d) {
      padding: $d;
    }
  }
}
