@use 'library' as steps;
@use '../core/library' as core;
@use '../icon/library' as icon;

.steps {
  display: flex;

  .steps-item {
    position: relative;

    &::before {
      @include steps.steps-item-number;
      @include steps.steps-item-number-size(steps.$steps-item-number-size);
      font-size: 1.25rem;
    }
  }

  &:not(.steps-has-icon) {
    .steps-item {
      @each $index in 1, 2, 3, 4, 5 {
        &:not(.no-number):nth-child(#{$index})::before {
          content: '#{$index}';
        }
      }

      &:not(.no-number).check::before {
        @include icon.glyph-icon(check);
      }
    }
  }

  &.steps-has-icon {
    .steps-item {
      @each $index in 1, 2, 3, 4, 5 {
        &:not(.no-number):nth-child(#{$index}) > .steps-icon::before {
          content: '#{$index}';
        }
      }

      &:not(.no-number).check > .steps-icon::before {
        @include icon.glyph-icon(check);
      }
    }
  }

  &.steps-l {
    .steps-item::before {
      @include steps.steps-item-number-size(72px);
      font-size: 1.75rem;

      @include core.sm {
        @include steps.steps-item-number-size(steps.$steps-item-number-size);
        font-size: 1.25rem;
      }
    }
  }

  &:not(.steps-vertical) {
    &.steps-has-icon {
      @include core.responsive(40px, 35px, 30px, 25px, 20px) using ($d) {
        margin-top: $d;
      }

      .steps-item {
        padding-top: steps.$steps-item-number-top-offset;

        &:not(:last-child)::after {
          top: 114px;

          @include core.md {
            display: none;
          }
        }
      }
    }

    .steps-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;

      &::before {
        margin-bottom: 16px;
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        border-bottom: 1px solid var(--divider-color);
        width: calc(
          100% - #{steps.$steps-item-number-size} - #{steps.$steps-item-icon-size}
        );
        top: calc(#{steps.$steps-item-number-size} / 2);
        left: calc(
          50% + #{steps.$steps-item-number-size} / 2 + #{steps.$steps-item-icon-size} /
            2
        );
      }

      .steps-icon {
        position: relative;

        &::before {
          @include steps.steps-item-number;
          @include steps.steps-item-number-size(steps.$steps-item-number-size);
          font-size: 1.25rem;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        width: steps.$steps-item-icon-size;
        height: steps.$steps-item-icon-size;
        z-index: -1;
      }

      &.no-number {
        .steps-icon {
          position: relative;
          top: 0;
          left: 0;
        }

        &::after {
          top: calc(#{steps.$steps-item-icon-size} / 2);
        }
      }
    }
  }

  &.steps-vertical {
    flex-direction: column;

    .steps-item {
      padding-left: 102px;
      padding-top: 18px;
      padding-bottom: 60px;

      @include core.sm {
        padding-left: 60px;
        padding-bottom: 20px;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;

        @include core.sm {
          top: 10px;
        }
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        border-left: 1px solid var(--divider-color);
        height: calc(100% - 112px);
        top: 92px;
        left: 36px;

        @include core.sm {
          height: calc(100% - 64px);
          top: 64px;
          left: 22px;
        }
      }
    }
  }
}
