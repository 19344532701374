@use '../palette/library' as palette;
@use '../form/library' as form;
@use '../core/library' as core;
@use '../shadow/library' as shadow;
@use '../icon/library' as icon;

@mixin button {
  font-family: 'Golos';
  display: inline-block;
  border: {
    style: solid;
    width: 1px;
  }
  cursor: pointer;
  text-align: center;
  outline: none;
  &:disabled,
  &.disabled {
    cursor: auto;
  }
  @include core.transition;
}

@mixin button-type(
  $bg-color,
  $bg-color-hover,
  $bg-color-disabled,
  $bd-color: $bg-color,
  $bd-color-hover: $bg-color-hover,
  $bd-color-disabled: $bg-color-disabled,
  $color,
  $color-disabled: $color,
  $box-shadow: none
) {
  background-color: $bg-color;
  border-color: $bd-color;
  color: $color;
  box-shadow: $box-shadow;

  &:not(.disabled) {
    @include core.hover {
      color: $color;
      background-color: $bg-color-hover;
      border-color: $bd-color-hover;
    }
  }

  &:disabled,
  &.disabled {
    color: $color-disabled;
    background-color: $bg-color-disabled;
    border-color: $bd-color-disabled;
    box-shadow: none;
  }
}

@mixin button-primary {
  @include button-type(
    $bg-color: var(--primary-color),
    $color: var(--contrast-text-color),
    $bg-color-hover: palette.getColor(green-600),
    $bg-color-disabled: palette.getColor(gray-300),
    $box-shadow: shadow.getShadow('green')
  );
}

@mixin button-secondary {
  @include button-type(
    $bg-color: var(--bg-color),
    $bd-color: var(--primary-color),
    $color: var(--primary-color),
    $bd-color-hover: var(--primary-color),
    $bg-color-hover: var(--input-bg-color-disabled),
    $bd-color-disabled: palette.getColor(gray-300),
    $bg-color-disabled: palette.getColor('white'),
    $color-disabled: palette.getColor(gray-400)
  );
}

@mixin button-subtle {
  @include button-type(
    $bg-color: var(--bg-color),
    $bd-color: var(--button-subtle-bd-color),
    $color: var(--text-color),
    $bg-color-hover: var(--input-bg-color-disabled),
    $bg-color-disabled: var(--input-bg-color-disabled),
    $color-disabled: palette.getColor(gray-500),
    $box-shadow: shadow.getShadow('white')
  );
}

@mixin button-subtle-bordered {
  border-color: var(--input-bd-color);
  box-shadow: none;

  &:not(.disabled):hover {
    @include core.hover {
      border-color: palette.getColor(gray-500);
    }
  }

  &:disabled,
  &.disabled {
    border-color: palette.getColor(gray-200);
  }
}

@mixin button-s {
  @include form.form-control-s;
  font-size: 0.875rem;
}

@mixin button-vi {
  @include button-type(
    $bg-color: palette.getColor(gray-1000),
    $color: palette.getColor('white'),
    $bg-color-hover: palette.getColor(gray-1000),
    $bg-color-disabled: palette.getColor(gray-300)
  );

  min-width: 44px;
  min-height: 28px;
  padding: 0 8px;
  border-radius: 3px;
  position: relative;

  &::after {
    position: absolute;
    font-size: 24px;
    line-height: 24px;
    transform: translate(-50%, -50%);
    color: palette.getColor('white');
    @include icon.glyph-icon(eye);
  }
}
