@use '../palette/library' as palette;
@use '../icon/library' as icon;
@use '../core/library' as core;

@mixin breadcrumbs {
  @include core.responsive(30px, 26px, 20px, 16px, 10px) using ($d) {
    padding: $d 0;
  }

  //скрываем breadcrumbs на мобильных устройствах
  @include core.md {
    display: none;
  }
}

@mixin breadcrumbs-item {
  @include core.responsive(0.875rem, 0.875rem, 0.75rem, 0.75rem, 10px) using
    ($d) {
    font-size: $d;
  }

  display: inline-block;
  color: var(--primary-color);
  &:not(:last-child):after {
    @include icon.glyph-icon(chevron-right);
    color: palette.getColor(gray-400);
    display: inline-block;
    margin: 0 4px;
    position: relative;
    top: 2px;
  }
  &:last-child {
    color: palette.getColor(gray-400);
  }
}

@mixin pageheader {
  @include core.responsive(2.5rem, 36px, 24px, 22px, 20px) using ($d) {
    font-size: $d;
  }
  @include core.md {
    margin-top: 1em;
  }
  margin-bottom: 1em;
}

@mixin pageheader-title {
  @include core.responsive(2.5rem, 2rem, 1.5rem, 1.5rem, 1.5rem) using ($d) {
    font-size: $d;
  }

  position: relative;
  color: var(--text-color);
  line-height: 130%;
}

@mixin pageheader-title-back-button {
  position: absolute;
  top: 0;
  right: calc(100% + 12px);
  @include core.lg {
    right: calc(100% + 8px);
  }
  font-size: 0.5em;
  cursor: pointer;

  &:before {
    @include icon.glyph-icon(arrow-left);
  }

  //скрываем back-button на мобильных устройствах
  @include core.md {
    display: none;
  }
}
