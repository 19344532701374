@use 'library' as tabs;

.tabs-nav {
  @include tabs.tabs-nav;

  .tabs-tab {
    @include tabs.tabs-tab;
    &.tabs-tab-active {
      @include tabs.tabs-tab-active;
    }
  }

  .tabs-extra {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
}
