@use '../core/library' as core;
@use '../palette/library' as palette;
@use 'library' as modal;

.cdk-global-scrollblock {
  // workaround to avoid wrong shift of re-captcha's validate image selector,
  // caused by ng-zorro implementation
  // (based on https://github.com/NG-ZORRO/ng-zorro-antd/issues/7365#issuecomment-1107475090)
  position: static !important;
  width: initial !important;
  overflow-y: inherit !important;
}

.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: core.$z-index-modal;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  z-index: 1000;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: modal.$modal-mask-bg;
}

.ant-modal-wrap {
  pointer-events: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: none;

  .ant-modal {
    position: relative;
    top: 100px;
    margin: 0 auto;
    padding-bottom: 100px;

    @include modal.modal-max-width;

    .ant-modal-content {
      @include modal.modal;

      .ant-modal-title {
        margin-right: 24px;
      }

      .ant-modal-close {
        @include modal.modal-header-close-button;

        position: absolute;
        right: 0;
        transform: translateY(0.5em);
        background: none;
        border: 0;
        outline: 0;

        @include core.responsive(48px, 36px, 24px, 20px, 20px) using ($d) {
          padding: $d;
        }

        .ant-modal-close-x {
          display: none;
        }
      }

      .ant-modal-header {
        @include modal.modal-header;
      }

      .ant-modal-body {
        @include modal.modal-body;
      }
    }
  }

  @at-root html:not(.visually-impaired) {
    .ant-modal.modal-with-bg .ant-modal-content {
      @include modal.modal-with-bg-img;
    }
  }
}
