@use 'library' as collapse;
@use '../typography/library' as typography;
@use 'sass:map';

.ant-collapse-item {
  @include collapse.collapse-item;

  .ant-collapse-header {
    @include collapse.collapse-header;
    @include collapse.collapse-header-with-plus;
    font-size: 1.25rem;
    font-weight: map.get(typography.$font-weights, regular);

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }

    .ant-collapse-arrow {
      display: none;
    }
  }

  &.ant-collapse-item-active {
    .ant-collapse-header {
      @include collapse.collapse-header-expanded-with-minus;
    }

    .ant-collapse-content {
      @include collapse.collapse-content;
    }
  }
}
