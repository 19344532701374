@use '../palette/library' as palette;
@use '../core/library' as core;
@use '../shadow/library' as shadow;

$dropdown-gutter: 10px;
$dropdown-icon-offset: 14px;

@mixin highlight-item {
  z-index: 1;
  position: relative;

  &::before {
    background-color: palette.getColor(gray-50);
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -6px;
    right: -6px;
    z-index: -1;
    border-radius: 3px;
  }
}

@mixin dropdown-item {
  cursor: pointer;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 20px;
  color: var(--text-color);

  @include core.hover {
    @include highlight-item;
  }
}

@mixin dropdown-paper {
  background-color: var(--bg-color);
  border-radius: var(--bd-radius);
  border: var(--dropdown-paper-border);
  @include shadow.shadow-white-plus;
}
