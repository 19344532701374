/** удалить файл после отказа от layout */

@use '../core/library' as core;
@use 'library' as modal;

.modal-mask,
.Modal-Fade,
.AdditionalModal-Fade {
  @include modal.modal-mask;

  .modal {
    box-shadow: none;
    border: 1px solid var(--divider-color);
  }
}

.modal,
.Modal-Container,
.AdditionalModal-Container {
  display: none;
  @include modal.modal;
  @include modal.modal-position-default;

  &.modal-position-top-center {
    @include modal.modal-position-top-center;
  }

  &.modal-position-center:not(.AdditionalModal-Container) {
    @include modal.modal-position-center;
  }

  .modal-header,
  .Modal-Header,
  .AdditionalModal-Header {
    @include modal.modal-header;

    .modal-header-close-button,
    #Modal-CloseButton,
    #AdditionalModal-CloseButton {
      @include modal.modal-header-close-button;
    }
  }

  .modal-body,
  .Modal,
  .AdditionalModal {
    @include modal.modal-body;
  }
}

.AdditionalModal-Container {
  &.modal-position-center {
    transform: translateX(-50%);
    z-index: 99999;
  }
}

.Modal-Container-Pano {
  position: absolute;
  @include core.rounded-s;
  left: 50%;

  min-width: 350px;
  display: none;
}

.Modal-Pano {
  padding: 20px;
}

.BigModal {
  width: 800px;
  max-width: 800px;
}

.Modal-Header-Pano {
  font-weight: bold;
  color: var(--medium-text-color);
  padding-top: 10px;
  padding-left: 20px;
}

#Modal-Header-Text {
  display: inline-block;
  vertical-align: middle;
}

//template modal
.modal-wrap {
  @include modal.modal-wrap;
  .modal {
    @include modal.modal;
    @include modal.modal-position-default;

    &.modal-position-top-center {
      @include modal.modal-position-top-center;
    }
    &.modal-position-center {
      @include modal.modal-position-center;
    }

    .modal-content {
      .modal-header {
        @include modal.modal-header;
      }
      .modal-body {
        @include modal.modal-body;
      }
    }
  }
}
