@use '../button/library' as button;
@use '../icon/library' as icon;
@use '../core/library' as core;

$margin: 24px;

.backtop {
  position: sticky;

  z-index: 1;

  margin-bottom: $margin;
  margin-top: calc(40px + 44px);
  height: 0;
  bottom: 40px;
  right: 44px;
  left: 44px;

  //скрываем на мобильных устройствах
  @include core.md {
    display: none;
  }

  .backtop-button {
    @include button.button;
    @include button.button-s;
    @include button.button-subtle;

    position: absolute;
    bottom: 0;
    right: 0;

    &:before {
      content: core.unicode('041D', '0430', '0432', '0435', '0440', '0445');
      margin-right: 8px;
    }
    &:after {
      @include icon.glyph-icon(arrow-top);
    }

    display: none;
  }
}
