.form-item {
  .form-item-label {
    font-size: 1rem;
    line-height: 140%;
    color: var(--medium-text-color);
    margin-bottom: 0.5em;
  }

  .form-item-control {
    .input,
    .select {
      width: 100%;
    }
  }

  &.form-item-required {
    .form-item-label {
      &:after {
        content: ' *';
      }
    }
  }
}
