@use 'library' as tag;

.tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  text-align: center;
  line-height: 8px;

  border: {
    style: solid;
    width: 1px;
  }

  @include tag.tag-gray;
  @include tag.tag-s;

  &.tag-violet {
    @include tag.tag-violet;
  }

  &.tag-white {
    @include tag.tag-white;
  }

  &.tag-m {
    @include tag.tag-m;

    &.tag-rounded {
      padding: 8px 12px;
      border-radius: 90px;
    }
  }

  &.tag-l {
    @include tag.tag-l;

    &.tag-rounded {
      padding: 10px 16px;
      border-radius: 90px;
    }
  }

  &.active {
    @include tag.active;
  }

  &.tag-bordered {
    @include tag.tag-bordered;
  }
}
