@use '../../core/library' as core;
@use '../library' as pageheader;

.ant-page-header {
  .ant-page-header-heading {
    @include pageheader.pageheader;
    @include pageheader.pageheader-title;
    .ant-page-header-back-button {
      @include pageheader.pageheader-title-back-button;
    }
  }
}

nz-page-header {
  display: block;
}
