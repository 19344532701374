@use 'library' as anchor;

.anchor {
  position: relative;

  .anchor-link {
    @include anchor.anchor-link;
    &.active {
      @include anchor.anchor-link-active;
    }
  }
}

.ant-anchor {
  position: relative;

  &-ink {
    display: none;
    &-ball {
      display: none;
    }
  }

  &-link {
    &-title {
      @include anchor.anchor-link;
    }
    &-active > &-title {
      @include anchor.anchor-link-active;
    }
  }
}
