@use '../palette/library' as palette;
@use '../icon/library' as icon;

$image-preview-operation-color: rgba(255, 255, 255, 0.6);

@mixin box($position: absolute) {
  position: $position;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin zoom-icon {
  .image {
    cursor: pointer;
    z-index: 0;
  }

  &::before {
    @include icon.glyph-icon(zoom);
    cursor: pointer;
    position: absolute;
    font-size: 24px;
    line-height: 24px;
    right: 8px;
    bottom: 8px;
    color: var(--primary-color);
    z-index: 1;
    background: var(--bg-color);
    border-radius: 50%;
    padding: 4px;
    pointer-events: none;
  }
}
