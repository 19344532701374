@use '../core/library' as core;
@use '../icon/library' as icon;

@mixin collapse-item {
  display: block;
  outline: none;
  border-bottom: 1px solid var(--divider-color);
}

@mixin collapse-header {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: var(--medium-text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include core.responsive(24px, 24px, 20px, 16px, 16px) using ($d) {
    padding: $d 0;
  }
}

@mixin collapse-header-with-plus {
  &::after {
    font-size: 1.5rem;

    @include core.sm {
      font-size: 1.25rem;
    }
    @include icon.glyph-icon(plus);
    margin-left: 12px;
  }
}

@mixin collapse-header-expanded-with-minus {
  color: var(--primary-color);

  &::after {
    @include icon.glyph-icon(minus);
  }
}

@mixin collapse-content {
  @include core.responsive(40px, 36px, 30px, 24px, 20px) using ($d) {
    padding-bottom: $d;
  }
}
