@use '../palette/library' as palette;

$z-index-modal: 5000003;
$z-index-modal-wrap: 5000002;

$breakpoints: (
  lg: 1200px,
  md: 992px,
  sm: 768px,
  xs: 576px,
);

@mixin hover {
  @at-root html:not(.disable-effects) &:not(.disabled):not(:disabled):hover {
    @content;
  }

  @at-root html:not(.disable-effects) &:focus {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: 576px) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin breakpoint($breakpoint) {
  @if ($breakpoint == lg) {
    @include lg {
      @content;
    }
  }
  @if ($breakpoint == md) {
    @include md {
      @content;
    }
  }
  @if ($breakpoint == sm) {
    @include sm {
      @content;
    }
  }
  @if ($breakpoint == xs) {
    @include xs {
      @content;
    }
  }
}

@mixin responsive($xl, $lg, $md, $sm, $xs) {
  @content ($xl);

  @media screen and (max-width: 1200px) {
    @content ($lg);
  }

  @media screen and (max-width: 992px) {
    @content ($md);
  }

  @media screen and (max-width: 768px) {
    @content ($sm);
  }

  @media screen and (max-width: 576px) {
    @content ($xs);
  }
}

@mixin rounded-size($d) {
  border-radius: $d;
}

@mixin rounded-xs {
  @include rounded-size(5px);
}

@mixin rounded-s {
  @include rounded-size(10px);
}

@mixin rounded-l {
  @include rounded-size(24px);
}

@mixin rounded-circle {
  @include rounded-size(50%);
}

@mixin transition {
  transition-property: color, background-color, border-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

@function unicode($strings...) {
  $result: '';
  @each $string in $strings {
    $result: $result + unquote(str-insert($string, '\\', 1));
  }
  @return unquote('"') + $result + unquote('"');
}

@mixin scroll-gradient-right {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 112px;
  background: linear-gradient(
    270deg,
    #ffffff 1.56%,
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none;
  content: '';
}

@mixin scroll-gradient-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 112px;
  background: linear-gradient(0deg, #ffffff 1.56%, rgba(255, 255, 255, 0) 100%);
  pointer-events: none;
  content: '';
}

@mixin gray-gradient {
  background: linear-gradient(270deg, #eff0f4 0%, rgba(239, 240, 244, 0) 100%);
}

@mixin white-gradient {
  background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

@mixin spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

@function capitalize($name) {
  @return to-upper-case(str-slice($name, 1, 1))+str-slice($name, 2);
}
