@use 'library' as palette;

html:not(.disable-palette) {
  @each $key, $value in palette.$colors {
    .#{$key} {
      color: $value;
    }
    .bg-#{$key} {
      background-color: $value;
    }
  }
}
