@use '../palette/library' as palette;
@use '../core/library' as core;
@use 'library' as card;

.card {
  display: inline-flex;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  @include card.card-m;

  &.card-s {
    @include card.card-s;
  }

  &.card-l {
    @include card.card-l;
  }

  &.card-border-1 {
    border: 1px solid palette.getColor(gray-400);
  }

  &.card-border-2 {
    border: 2px solid palette.getColor(gray-200);
  }

  .card-background {
    position: absolute;
    height: 100%;
    top: 0;

    &.card-background-right {
      transform-origin: 100% 100%;
      right: 0;
    }
  }

  > .card-body {
    flex-grow: 1;
    z-index: 1;

    & + .card-footer {
      border-top: inherit;
    }
  }

  > .card-header {
    & + .card-body {
      border-top: inherit;
    }
  }

  &.card-hoverable {
    cursor: pointer;
    transition: all 0.15s ease-in;

    .card-effect-scale {
      transition: all 0.15s ease-in;
    }

    @include core.hover {
      box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);

      &.card-border-1,
      &.card-border-2 {
        box-shadow: none;
        border-color: palette.getColor(green-100);
      }

      .card-effect-scale {
        transform: scale(1.1);
      }
    }
  }
}

.card-decoration-container {
  position: relative;

  .tag {
    transform: translateY(-50%);
    left: 36px + 3px;
    position: absolute;
    z-index: 1;
  }
}
