@use '../palette/library' as palette;
@use '../core/library' as core;
@use '../form/library' as form;

@mixin input-placeholder {
  color: palette.getColor(gray-300) !important;
  opacity: 1;
}

@mixin input {
  outline: none;
  border: 1px solid var(--input-bd-color);
  color: var(--text-color);
  background-color: var(--bg-color);
  -webkit-appearance: none;

  @include core.hover {
    border-color: palette.getColor(gray-500);
  }
  @include core.transition;

  &::placeholder {
    @include input-placeholder;
  }
  &:-ms-input-placeholder {
    @include input-placeholder;
  }
}

@mixin input-disabled {
  @at-root html:not(.visually-impaired) & {
    border-color: var(--input-bg-color-disabled);
  }
  background-color: var(--input-bg-color-disabled);
  color: palette.getColor(gray-300);
  opacity: 1;
}

@mixin input-cancel-button {
  width: 24px;
  height: 24px;
  display: block;
  background: url('./cross.svg') no-repeat center;
  margin-right: 4px;
  cursor: pointer;
}
