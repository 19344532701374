@use '../typography/library' as typography;
@use '../icon/library' as icon;
@use 'library' as dropdown;
@use '../core/library' as core;

.dropdown {
  @include dropdown.dropdown-paper;

  padding-top: 32px;
  padding-bottom: 32px;

  left: 0;
  top: calc(100% + #{dropdown.$dropdown-gutter});
  position: absolute;
  z-index: 10000;

  &.dropdown-anchor-right {
    right: 0;
    left: auto;
  }

  &.dropdown-menu {
    padding: 16px;
    min-width: 100%;
  }

  &.dropdown-scrollable {
    .dropdown-body {
      overflow-y: auto;
    }
  }

  .dropdown-body {
    padding-left: 32px;
    padding-right: 32px;
  }

  .dropdown-header {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 16px;
  }

  .dropdown-item {
    @include dropdown.dropdown-item;
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
  padding-right: dropdown.$dropdown-icon-offset;

  .dropdown {
    display: none;
  }

  .dropdown-trigger {
    position: relative;

    &::after {
      @include icon.glyph-icon(chevron-down);
      font-size: 12px;
      line-height: 1;
      position: absolute;
      right: -#{dropdown.$dropdown-icon-offset};
      top: 50%;
      transform: translateY(-40%);
    }
  }

  &:not(:disabled):hover,
  &:focus {
    .dropdown {
      display: block;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: dropdown.$dropdown-gutter;
      bottom: -#{dropdown.$dropdown-gutter};
    }

    .dropdown-trigger {
      cursor: pointer;
    }
  }
}
