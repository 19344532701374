@use '../palette/library' as palette;
@use '../icon/library' as icon;
@use '../steps/library' as steps;
@use '../core/library' as core;

@mixin markerlist-check-icon {
  &::before {
    @include icon.glyph-icon(check);
    color: palette.getColor(green-basic);
    top: 0.05em;
  }
}

@mixin markerlist-check-icon-l {
  &:before {
    @include icon.glyph-icon(check);
    @include steps.steps-item-number;
    @include steps.steps-item-number-size(44px);
    font-size: 1.5em;
    box-sizing: border-box;
    padding-top: 2px;
  }
}

@mixin markerlist-bullet-icon {
  &:before {
    content: core.unicode('25CF');
    color: palette.getColor(violet-light);
    top: -0.05em;
  }
}

@mixin marketlist-hyphen-icon {
  &:before {
    content: '-';
    color: inherit;
  }
}
