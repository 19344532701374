@use '../palette/library' as palette;
@use '../icon/library' as icon;
@use '../core/library' as core;
@use '../shadow/library' as shadow;

@mixin arrow {
  color: var(--medium-text-color);
  position: absolute;
  background-color: var(--bg-color);
  top: 50%;
  border-radius: 24px;
  text-shadow: none;

  @include core.responsive(20px, 18px, 18px, 16px, 14px) using ($d) {
    font-size: $d;
    line-height: $d;
  }
  @include core.responsive(14px, 12px, 12px, 10px, 8px) using ($d) {
    padding: $d;
  }

  @at-root html:not(.visually-impaired) & {
    @include shadow.shadow-white-plus;
  }

  @at-root .visually-impaired & {
    border: 1px solid var(--primary-color);
  }
}

@mixin nav-left {
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &::before {
    @include arrow;
    @include icon.glyph-icon(chevron-left);
    left: 0;
    transform: translate(-50%, -50%);
  }
}

@mixin nav-right {
  outline: none;
  -webkit-tap-highlight-color: transparent;

  &::before {
    @include arrow;
    @include icon.glyph-icon(chevron-right);
    right: 0;
    transform: translate(50%, -50%);
  }
}
