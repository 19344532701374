@use '../input/library' as input;
@use '../palette/library' as palette;
@use '../icon/library' as icon;

$control-icon-size: 1.25rem;

@mixin select {
  @include input.input;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin select-dropdown-item-selected {
  font-weight: 600;
  color: var(--select-item-selected);
  background-color: var(--select-contrast-color);
}

@mixin select-dropdown-item-disabled {
  color: palette.getColor(gray-300);
}

@mixin select-right-icon {
  position: absolute;
  right: 14px;
  top: calc(50% - #{$control-icon-size} / 2);
  color: var(--text-color);
  font-size: $control-icon-size;
  line-height: $control-icon-size;

  width: $control-icon-size;
  height: $control-icon-size;
}

@mixin select-clear {
  @include select-right-icon;

  cursor: pointer;
  background: var(--bg-color);

  &:before {
    @include icon.glyph-icon(cross-1);
  }
}
