@use '../_library' as pageheader;

.ant-breadcrumb {
  @include pageheader.breadcrumbs;
  nz-breadcrumb-item {
    @include pageheader.breadcrumbs-item;
  }
  .ant-breadcrumb-separator {
    display: none;
  }
}

nz-breadcrumb {
  display: block;
}
