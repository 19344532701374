@use '../palette/library' as palette;
@use '../core/library' as core;

.list {
  .list-item {
    transition: all 0.15s ease-in;
    padding: 8px;
    border-bottom: 1px solid var(--divider-color);

    @media (any-hover: hover) {
      @include core.hover {
        background-color: palette.getColor(gray-50);
      }
    }
  }

  .list-subheader {
    padding: 8px;
    margin-top: 16px;
    font-size: 1.5rem;
    line-height: 150%;
    color: var(--primary-color);
  }
}
