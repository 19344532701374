@use '../shadow/library' as shadow;
@use '../palette/library' as palette;
@use '../icon/library' as icon;
@use '../dropdown/library' as dropdown;
@use '../datepicker/library' as datepicker;

.ant-picker-time-panel {
  font-family: inherit;
  font-size: 0.875rem;
  color: var(--text-color);

  .ant-picker-content {
    @include dropdown.dropdown-paper;

    display: flex;
    height: 244px;
    padding: 8px;
    margin: 6px 12px 6px 0;
  }

  .ant-picker-time-panel-column {
    width: 52px;
    overflow-y: auto;

    list-style: none;
    margin: 0;
    padding: 0;

    &:first-child {
      border-right: 1px solid palette.getColor(gray-50);
    }

    > li.ant-picker-time-panel-cell {
      width: 100%;
      height: 38px;

      display: flex;
      align-items: center;
      justify-content: center;

      .ant-picker-time-panel-cell-inner {
        display: block;
        width: 28px;
        height: 22px;
        border-radius: 4px;
        cursor: pointer;

        text-align: center;
        line-height: 22px;
      }

      &.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
        background: var(--primary-color);
        color: var(--contrast-text-color);
      }

      &.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
        cursor: default;
        color: palette.getColor(gray-300);
      }

      &:not(.ant-picker-time-panel-cell-disabled)
        .ant-picker-time-panel-cell-inner:hover {
        background: var(--primary-color);
        color: var(--contrast-text-color);
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-picker-footer {
    display: none;
  }
}

nz-time-picker .ant-picker-input .ant-picker-suffix {
  > .anticon-clock-circle {
    display: none;
  }

  &::after {
    @include datepicker.datepicker-right-icon;
    @include icon.glyph-icon(time);
  }
}
