@use '../core/library' as core;

@mixin alert-padding {
  @include core.responsive(16px 24px, 16px 20px, 16px 20px, 12px 16px, 8px 12px)
    using ($d) {
    padding: $d;
  }
}

@mixin alert-before {
  @include core.responsive(20px, 16px, 16px, 12px, 8px) using ($d) {
    margin-right: $d;
  }
}
