@use '../sizes/library' as sizes;
@use './glyph-icons/generated/glyph-icons';
@use './svg-icons/generated/svg-icons';
@use '../palette/library' as palette;
@use '../core/library' as core;
@use 'library' as icon;

@font-face {
  font-family: glyph-icons;
  src: url('data:font/truetype;charset=utf-8;base64,#{glyph-icons.$base64}')
    format('woff');
}

%svg-icon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 1em;
  height: 1em;
}

%glyph-icon {
  font-family: glyph-icons;
  text-align: center;
  position: relative;
  top: 0.05em;
  line-height: 1;
}

.icon {
  display: inline-block;

  &.icon-2x {
    width: 2em;
    transform: scale(2);
  }
  &.icon-1\.5x {
    width: 1.5em;
    transform: scale(1.5);
  }

  @each $size in sizes.$sizes {
    &.icon-#{$size} {
      font-size: #{$size}px;
    }
  }

  @each $icon in svg-icons.$svg-icons {
    &.icon-#{$icon} {
      @extend %svg-icon;
      background-image: url(./svg-icons/svg/#{$icon}.svg);
    }
  }

  @each $name, $glyph in glyph-icons.$glyph-icons {
    &.icon-#{$name} {
      @extend %glyph-icon;
      &:before {
        content: core.unicode($glyph);
      }
    }
  }

  @at-root html:not(.disable-palette) & {
    @each $key, $value in palette.$colors {
      &.bg-slime-#{$key} {
        &:after {
          color: $value;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          @include icon.glyph-icon(slime);
        }
      }
    }
  }
}
