.tox-notifications-container {
  display: none;
}

.html-markup {
  * {
    float: none !important;
    height: auto !important;
  }

  @import 'typography';
  @import 'list';
  @import 'table';
  @import 'link';

  overflow: auto;

  img {
    float: none !important;
    height: auto !important;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 10px;
  }

  hr {
    border: none;
    margin-top: 28px !important;
    margin-bottom: 28px !important;
    border-top: 1px solid var(--divider-color);
  }

  .Work-Format-Column-Cms {
    display: inline-block;
    vertical-align: top;
    max-width: 800px;
    margin-bottom: 1em;

    &.Work-Format-Left-Icon {
      margin-right: 20px;
    }
  }
}
