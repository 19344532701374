@use '../palette/library' as palette;
@use '../core/library' as core;

.section {
  @include core.responsive(44px, 36px, 28px, 20px, 12px) using ($d) {
    padding: 0 $d;
  }

  &.section-border-bottom {
    border-bottom: 1px solid var(--divider-color);
  }

  > .section-content {
    margin: 0 auto;
    max-width: 1320px;
  }

  &.section-has-sider {
    > .section-content {
      display: flex;
      flex-wrap: nowrap;

      &.section-sider-mobile-top {
        > .section-sider {
          @include core.responsive(unset, unset, unset, 40px, 24px) using ($d) {
            margin-bottom: $d;
          }
        }
        @include core.sm {
          flex-direction: column-reverse;
        }
      }

      &.section-sider-mobile-bottom {
        > .section-sider {
          @include core.responsive(unset, unset, unset, 40px, 24px) using ($d) {
            margin-top: $d;
          }
        }
        @include core.sm {
          flex-direction: column;
        }
      }

      > .section-main {
        flex-grow: 1;
        // IE11
        width: 100%;
      }

      > .section-sider {
        @include core.responsive(76px, 56px, 40px, unset, unset) using ($d) {
          margin-left: $d;
        }
        @include core.sm {
          width: 100%;
        }

        // IE11
        > * {
          width: 100%;
        }

        &.section-sider-s {
          min-width: 300px;
          max-width: 300px;

          @include core.sm {
            width: 100%;
            min-width: unset;
            max-width: unset;
          }
        }

        &.section-sider-mobile-full-screen {
          @include core.responsive(unset, unset, unset, 20px, 12px) using ($d) {
            padding: 0 $d;
          }
          @include core.sm {
            position: fixed;
            top: 0;
            height: 100vh;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: none;
            margin-left: 0;
            background-color: #fff;
            overflow: auto;
            display: none;
          }
        }
      }
    }
  }

  &.section-has-bg-image {
    position: relative;
    overflow: hidden;

    > .section-bg-image {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      min-height: 100%;
    }

    > .section-content {
      position: relative;
      z-index: 1;
    }
  }
}

.footer-bottom-navigation {
  @include core.md {
    margin-bottom: 69px;
  }
}
