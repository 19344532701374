@use 'sass:map';
@use '../shadow/library' as shadow;
@use '../palette/library' as palette;
@use '../typography/library' as typography;
@use '../core/library' as core;
@use '../dropdown/library' as dropdown;

$modal-max-width: 1090px;
$modal-mask-bg: rgba(0, 0, 0, 0.45);

@mixin modal-max-width {
  max-width: calc(100vw - 32px);

  @include core.sm {
    max-width: calc(100vw - 16px);
  }
}

@mixin modal-margin {
  margin: 0 auto;

  @include core.sm {
    margin: 8px auto;
  }
}

@mixin modal-mask {
  background-color: $modal-mask-bg;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 1;
  position: absolute;
  padding-bottom: 48px;
  z-index: core.$z-index-modal-wrap;
}

@mixin modal {
  @include dropdown.dropdown-paper;

  z-index: core.$z-index-modal;
  @include modal-max-width;
  @include modal-margin;
  position: relative;
  overflow: hidden;
}

@mixin modal-body {
  @include core.responsive(48px, 36px, 24px, 20px, 20px) using ($d) {
    padding: $d;
  }
}

@mixin modal-header {
  @include typography.typography(
    map.get(typography.$typography-variants, h1)...
  );

  @include core.responsive(48px, 36px, 24px, 20px, 20px) using ($d) {
    padding: $d $d 0 $d;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin modal-header-close-button {
  cursor: pointer;
  margin-left: 10px;

  &::before {
    color: palette.getColor(gray-400);
    content: core.unicode('2715');
    line-height: 1;
    font-size: 24px;

    @include core.sm {
      font-size: 16px;
    }
  }
}

@mixin modal-position-default {
  top: 100px;
}

@mixin modal-position-top-center {
  @include core.responsive(50px, 48px, 40px, 36px, 24px) using ($d) {
    top: $d;
  }
}

@mixin modal-position-center {
  top: 50%;
  transform: translateY(-50%);
}

//template modal
@mixin modal-wrap {
  @include modal-mask;
  position: fixed;
  right: 0;
  bottom: 0;
  overflow: auto;
}

@mixin modal-with-bg-img {
  background-image: url('assets/bg.svg');
  background-repeat: no-repeat;
  background-position: right bottom;

  @include core.xs {
    padding-bottom: 180px;
  }
}
