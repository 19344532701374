@use '../palette/library' as palette;
@use '../shadow/library' as shadow;
@use '../icon/library' as icon;
@use '../core/library' as core;
@use '../typography/library' as typography;
@use 'sass:map';

@mixin tabs-nav {
  display: inline-flex;
  overflow-x: auto;
  width: 100%;
  list-style-type: none;
  padding: 0;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin tabs-nav-gray {
  border-radius: var(--bd-radius);
  background-color: var(--tabs-nav-gray-bg);
}

@mixin tabs-nav-vertical {
  display: flex;
  flex-direction: column;
  overflow: visible;
}

@mixin tabs-tab {
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  color: var(--tabs-text-color);
  padding: 12px 0;
  font-size: 1rem;
  font-weight: map.get(typography.$font-weights, medium);
  position: relative;

  &:not(:first-child) {
    margin-left: 24px;
  }

  * {
    outline: none;
  }
}

@mixin tabs-tab-active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  @at-root .visually-impaired & {
    border-bottom: 3px solid var(--button-contrast-color);
  }
}

@mixin tabs-tab-vertical {
  cursor: pointer;
  outline: none;
  border: 1px solid var(--divider-color);
  padding: 10px 18px;
  color: var(--tabs-text);

  @at-root html:not(.visually-impaired) & {
    border-radius: 10px;
  }
}

@mixin tabs-tab-vertical-active {
  @include shadow.shadow-green;

  border: 1px solid palette.getColor(green-30);
  background: palette.getColor(green-30);
  color: var(--button-contrast-color);

  &::after {
    height: unset;
    background: unset;
    width: unset;
    bottom: unset;
  }

  @at-root .visually-impaired & {
    border: 1px solid var(--contrast-text-color);
    background: var(--primary-color);
  }
}

@mixin tabs-tab-vertical-active-icon {
  @include tabs-tab-vertical-active;
  position: relative;
  display: flex;
  align-items: center;
  &::after,
  &::before {
    position: absolute;
    color: var(--tabs-text-color);
    font-size: 1.5rem;
    line-height: 1;
  }
}

@mixin tabs-tab-vertical-active-icon-right {
  @include tabs-tab-vertical-active-icon;

  &::after {
    @include icon.glyph-icon(chevron-right);
    left: calc(100% + 6px);
  }
}

@mixin tabs-tab-vertical-active-icon-left {
  @include tabs-tab-vertical-active-icon;

  &::before {
    @include icon.glyph-icon(chevron-left);
    right: calc(100% + 6px);
  }
}

@mixin tabs-content {
  @include core.responsive(32px, 28px, 24px, 20px, 16px) using ($d) {
    margin-top: $d;
  }
}

@mixin tabs-content-vertical {
  margin: 0;
}

@mixin tabs-tab-vertical-hover {
  @include core.hover {
    background-color: palette.getColor(gray-50);
  }
}
