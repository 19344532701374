@use '../palette/library' as palette;
@use '../icon/library' as icon;
@use 'library' as alert;

.alert {
  display: flex;
  font-size: 0.875rem;

  .alert-content {
    .alert-title {
      font-weight: 600;
    }

    .alert-message {
      font-weight: 400;
    }
  }

  &:before {
    @include alert.alert-before;
  }

  &.alert-info {
    &:before {
      line-height: 24px;
      font-size: 24px;
      @include icon.glyph-icon(attention);
    }
  }

  &.alert-info-circle {
    &:before {
      line-height: 24px;
      font-size: 24px;
      @include icon.glyph-icon(alert-circle);
    }
  }

  &.alert-primary {
    color: palette.getColor(gray-900);
    @include alert.alert-padding;
    background-color: palette.getColor(green-30);
    border-radius: 7px;
    &:before {
      color: palette.getColor(green-basic);
    }
  }

  &.alert-secondary {
    color: palette.getColor(gray-800);
    &:before {
      color: palette.getColor(gray-800);
    }

    &.alert-info {
      &:before {
        @include icon.glyph-icon(alert-triangle);
      }
    }
  }

  &.alert-subtle {
    color: palette.getColor(gray-400);
    &:before {
      color: palette.getColor(gray-600);
    }

    &.alert-info {
      &:before {
        @include icon.glyph-icon(alert-triangle);
      }
    }
  }

  &.alert-attention {
    color: palette.getColor(gray-800);
    @include alert.alert-padding;
    border: 1px solid palette.getColor('red');
    border-radius: 10px;
    * > .alert-message {
      font-weight: 500;
    }

    &:before {
      color: palette.getColor('red');
    }

    &.alert-subtle {
      border: 0;
      color: palette.getColor(gray-600);
    }
  }
}
