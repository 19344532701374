@use 'library' as empty;

.empty {
  @include empty.empty-l;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  line-height: 140%;
  color: var(--tabs-text-color);

  &::before {
    content: '';
    background-size: 100%;
    background-image: url(./notfound.svg);
  }

  &.empty-s {
    @include empty.empty-s;
  }
}
