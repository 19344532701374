@use '../shadow/library' as shadow;
@use '../dropdown/library' as dropdown;

.ant-tooltip {
  position: relative;
  box-sizing: border-box;
  font-size: 1rem;
  z-index: 2;
  display: block;
  max-width: 250px;

  &-placement {
    &-top {
      padding-bottom: 15px;

      .ant-tooltip-arrow {
        left: 50%;
        bottom: 0;
        transform: translateY(100%) translate(-50%);

        &-content {
          transform: translateY(-16px) rotate(45deg);
        }
      }
    }

    &-right {
      padding-left: 15px;

      .ant-tooltip-arrow {
        top: 50%;
        left: 0;
        transform: translate(-100%) translateY(-50%);

        &-content {
          transform: translate(16px) rotate(135deg);
        }
      }
    }

    &-left {
      padding-right: 15px;

      .ant-tooltip-arrow {
        top: 50%;
        right: 0;
        transform: translate(100%) translateY(-50%);

        &-content {
          transform: translate(-16px) rotate(315deg);
        }
      }
    }

    &-bottom {
      padding-top: 15px;

      .ant-tooltip-arrow {
        left: 50%;
        top: 0;
        transform: translateY(-100%) translate(-50%);

        &-content {
          transform: translateY(16px) rotate(225deg);
        }
      }
    }
  }

  &-content {
    position: relative;
  }

  &-inner {
    @include dropdown.dropdown-paper;

    font-size: 1rem;
    min-width: 30px;
    min-height: 32px;
    padding: 14px 16px;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
  }

  &-arrow {
    position: absolute;
    z-index: 2;
    display: block;
    width: 32px;
    height: 32px;
    pointer-events: none;

    &-content {
      font-size: 1rem;
      position: absolute;
      inset: 0;
      display: block;
      width: 16px;
      height: 16px;
      margin: auto;
      content: '';
      pointer-events: none;
      background: var(--bg-color);
      color: var(--tabs-text-color);
      box-shadow: shadow.getShadow(white-plus);
      border: {
        right: var(--dropdown-paper-border);
        bottom: var(--dropdown-paper-border);
        radius: 0 0 2px;
      }

      &::before {
        position: absolute;
        top: -16px;
        left: -16px;
        width: 33px;
        height: 33px;
        background: var(--bg-color);
        background-repeat: no-repeat;
        background-position: -10px -10px;
        content: '';
        clip-path: path(
          'M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z'
        );
      }
    }
  }
}
