@use '../sizes/library' as sizes;

.affix {
  z-index: 2;
  position: sticky;

  &.affix-top {
    top: 0;
  }

  @each $size in sizes.$sizes {
    &.affix-top-#{$size} {
      top: #{$size}px;
    }
  }
}
