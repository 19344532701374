@use 'sass:map';
@use 'sass:math';
@use '../core/library' as core;

$font-weights: (
  regular: 400,
  medium: 500,
  bold: 600,
);

@mixin font-regular {
  font-family: Golos;
  font-weight: map.get($font-weights, regular);
}

@mixin font-bold {
  font-family: Golos;
  font-weight: map.get($font-weights, bold);
}

$typography-variants: (
  hero: 3.125rem 2.25rem 2rem 130%,
  huge: 2.5rem 1.875rem 1.75rem 140%,
  h1: 1.875rem 1.625rem 1.5rem 140%,
  h2: 1.75rem 1.5rem 1.375rem 150%,
  h3: 1.5rem 1.375rem 1.25rem 150%,
  h4: 1.25rem 1.25rem 1.125rem 150%,
  headline: 1rem 1rem 1rem 140%,
  body: 0.875rem 0.875rem 0.875rem 150%,
  subheadline: 0.75rem 0.75rem 0.75rem 170%,
  caption: 0.75rem 0.75rem 0.75rem 170%,
);

@mixin typography($xl, $md, $xs, $lineHeight) {
  line-height: $lineHeight;

  @include core.responsive($xl, $xl, $md, $md, $xs) using ($d) {
    font-size: $d;

    @each $count in 2, 3, 4 {
      $height: math.div($d * $count * $lineHeight, 100%);
      &.typography-lines-#{$count} {
        height: $height;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: $count;
        -webkit-box-orient: vertical;
      }

      &.typography-max-lines-#{$count} {
        max-height: $height;
        height: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: $count;
        -webkit-box-orient: vertical;
      }
    }
  }
}
