@use '../palette/library' as palette;

:root {
  --bg-color: #{palette.getColor('white')};
  --primary-color: #{palette.getColor(green-basic)};
  --primary-color-light: #{palette.getColor(green-100)};

  --text-color: #{palette.getColor(gray-1000)};
  --medium-text-color: #{palette.getColor(gray-800)};
  --contrast-text-color: #{palette.getColor('white')};
  --contrast-text-color-with-bg: #{palette.getColor(gray-1000)};

  --bd-radius: 6px;

  --body-font-size: 16px;

  --button-xs-text-size: 10px;
  --button-subtle-bd-color: var(--bg-color);
  --button-contrast-color: var(--primary-color);

  --select-item-selected: var(--text-color);
  --select-contrast-color: #{palette.getColor('white')};

  --input-bd-color: #{palette.getColor(gray-400)};
  --input-bg-color-disabled: #{palette.getColor(gray-50)};

  --dropdown-paper-border: none;

  --divider-color: #{palette.getColor(gray-200)};

  --tabs-text-color: #{palette.getColor(gray-600)};
  --tabs-nav-gray-bg: #{palette.getColor(gray-100)};

  --tag-active-bg: #{palette.getColor(violet-light)};
  --tag-active-text: #{palette.getColor(violet-dark)};
}

.visually-impaired {
  --bg-color: #{palette.getColor('white')};
  --primary-color: #{palette.getColor('black')};
  --primary-color-light: var(--primary-color);

  --text-color: var(--primary-color);
  --medium-text-color: var(--primary-color);
  --contrast-text-color: var(--bg-color);
  --contrast-text-color-with-bg: var(--bg-color);

  --bd-radius: 0;

  --body-font-size: 22px;

  --button-xs-text-size: 20px;
  --button-subtle-bd-color: var(--primary-color);
  --button-contrast-color: var(--contrast-text-color);

  --select-item-selected: var(--bg-color);
  --select-contrast-color: var(--primary-color);

  --input-bd-color: var(--primary-color);

  --dropdown-paper-border: 1px solid var(--primary-color);

  --divider-color: var(--primary-color);

  --tabs-text-color: var(--primary-color);
  --tabs-nav-gray-bg: var(--primary-color);

  --tag-active-bg: var(--primary-color);
  --tag-active-text: var(--bg-color);

  background-color: var(--bg-color);
  background-image: none !important;

  * {
    box-shadow: none !important;
  }

  img {
    display: none !important;
  }

  .image-container {
    display: none;
  }

  &.visually-impaired-black {
    --bg-color: #000;
    --primary-color: #fff;
  }

  &.visually-impaired-brown {
    --bg-color: #3b2716;
    --primary-color: #a9e44d;
  }

  &.standard-font-size {
    --body-font-size: 18px;
  }

  &.large-font-size {
    --body-font-size: 22px;
  }

  &.x-large-font-size {
    --body-font-size: 25px;
  }

  .affix-top-40 {
    top: 200px !important;
  }

  .alert {
    color: var(--text-color) !important;
    background-color: var(--bg-color) !important;

    &:not(.alert-subtle) {
      border-radius: 0 !important;
      border: 1px solid var(--primary-color) !important;
    }
  }

  .tag {
    border-radius: 0 !important;

    &:not(.active, .tag-violet) {
      color: var(--primary-color) !important;
      background-color: var(--bg-color) !important;
      border: 1px solid var(--primary-color) !important;
    }
  }

  .typography {
    color: var(--primary-color);
  }

  .section {
    background-color: var(--bg-color) !important;
  }

  .card {
    background-color: var(--bg-color) !important;
    border: 1px solid var(--primary-color) !important;
    border-radius: 0 !important;

    .card-background {
      display: none;
    }
  }

  .markerlist {
    > li:before {
      color: var(--primary-color) !important;
      background: none !important;
    }
  }

  .ant-picker-wrapper {
    border: 1px solid !important;
    border-color: var(--contrast-text-color);
  }
}
