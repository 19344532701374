@use '../palette/library' as palette;
@use '../modal/library' as modal;
@use '../core/library' as core;
@use 'library' as image;

.ant-image {
  position: relative;
  display: inline-block;

  &-img {
    display: block;
    width: 100%;
    height: auto;

    &-placeholder {
      background-color: palette.getColor(gray-50);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30%;
    }
  }

  &-mask {
    @include image.box(fixed);
    z-index: core.$z-index-modal;
    height: 100%;
    background-color: modal.$modal-mask-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    color: palette.getColor('white');
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;

    &-info {
      .anticon {
        margin-inline-end: 4px;
      }
    }

    &-hidden {
      display: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  &-placeholder {
    @include image.box;
  }

  &-preview {
    @include modal.modal-mask;
    pointer-events: none;

    height: 100%;
    text-align: center;

    &-body {
      @include image.box;
      overflow: hidden;
    }

    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      transform: scale3d(1, 1, 1);
      cursor: grab;
      transition: transform 0.3s ease-out 0s;
      user-select: none;
      pointer-events: auto;

      &-wrapper {
        @include image.box;
        transition: transform 0.3s ease-out 0s;

        &::before {
          display: inline-block;
          width: 1px;
          height: 50%;
          margin-right: -1px;
          content: '';
        }
      }
    }

    &-moving {
      .ant-image-preview-img {
        cursor: grabbing;

        &-wrapper {
          transition-duration: 0s;
        }
      }
    }

    &-wrap {
      @include image.box(fixed);
      z-index: core.$z-index-modal + 80;
      overflow: auto;
      outline: 0;
      -webkit-overflow-scrolling: touch;
    }

    &-operations {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      color: image.$image-preview-operation-color;
      list-style: none;
      background: rgba(0, 0, 0, 0.4);
      pointer-events: auto;

      &-operation {
        margin-left: 1rem;
        padding: 1rem;
        cursor: pointer;

        &-disabled {
          color: rgba(0, 0, 0, 0.2);
          pointer-events: none;
        }

        &:last-of-type {
          margin-left: 0;
        }
      }

      &-icon {
        font-size: 1rem;
      }
    }

    &-switch-left,
    &-switch-right {
      position: absolute;
      top: 50%;
      right: 10px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      margin-top: -22px;
      color: image.$image-preview-operation-color;
      background: rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      cursor: pointer;
      pointer-events: auto;

      &-disabled {
        display: none;
      }

      > .anticon {
        font-size: 18px;
      }
    }

    &-switch-left {
      left: 10px;
    }

    &-switch-right {
      right: 10px;
    }
  }
}
