@mixin empty-size($image-size, $description-width, $padding-y) {
  padding: $padding-y calc(50% - #{$description-width} / 2);

  &::before {
    width: $image-size;
    height: $image-size;
  }
}

@mixin empty-s {
  @include empty-size(80px, 360px, 12px);
}

@mixin empty-l {
  @include empty-size(128px, 440px, 40px);
}
