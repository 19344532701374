@use '../palette/library' as palette;
@use '../typography/library' as typography;

.badge {
  font-family: 'Golos';
  display: inline-block;
  position: absolute;
  top: -5px;
  left: calc(100% - 10px);
  font-size: 12px;
  line-height: 20px;
  min-width: 20px;
  color: var(--contrast-text-color-with-bg);
  background-color: var(--primary-color-light);
  padding: 0 6px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
