@use '../core/library' as core;
@use 'library' as sizes;

@each $size in sizes.$sizes {
  @each $alias, $name in ('p': 'padding', 'm': 'margin') {
    @each $side-alias, $side-name in sizes.$sides {
      .#{$alias}#{$side-alias}-#{$size} {
        @include core.responsive(1, 0.875, 0.75, 0.625, 0.5) using ($d) {
          #{$name}-#{$side-name}: #{$size * $d}px;
        }
      }
    }
    .#{$alias}x-#{$size} {
      @extend .#{$alias}l-#{$size};
      @extend .#{$alias}r-#{$size};
    }
    .#{$alias}y-#{$size} {
      @extend .#{$alias}t-#{$size};
      @extend .#{$alias}b-#{$size};
    }
    .#{$alias}-#{$size} {
      @extend .#{$alias}l-#{$size};
      @extend .#{$alias}r-#{$size};
      @extend .#{$alias}t-#{$size};
      @extend .#{$alias}b-#{$size};
    }
  }
}

@each $size in sizes.$sizes {
  @each $alias, $property in ('h': 'height', 'w': 'width') {
    .#{$alias}-#{$size} {
      #{$property}: #{$size}px !important;
    }
    .min-#{$alias}-#{$size} {
      min-#{$property}: #{$size}px;
    }
    .max-#{$alias}-#{$size} {
      max-#{$property}: #{$size}px;
    }
  }
}
