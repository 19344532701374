@use '../typography/library' as typography;
@use '../palette/library' as palette;
@use '../icon/library' as icon;
@use 'sass:map';

@mixin anchor-link {
  @include typography.typography(
    map.get(typography.$typography-variants, body)...
  );
  position: relative;
  display: block;
  color: var(--medium-text-color);
  border-top: 1px solid palette.getColor(gray-100);
  padding: 8px 0;
  cursor: pointer;
}

@mixin anchor-link-active {
  color: var(--primary-color);
  font-weight: map.get(typography.$font-weights, medium);

  &::before {
    position: absolute;
    right: calc(100% + 8px);
    @include icon.glyph-icon(chevron-left);
  }
}
