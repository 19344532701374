table,
tr,
td,
th {
  border-collapse: collapse;
  border: 1px solid var(--divider-color);

  > p {
    margin-bottom: 0 !important;
  }
}

table {
  color: var(--text-color);

  td,
  th {
    padding: 4px 8px;

    > p:last-child {
      margin-bottom: 0;
    }
  }
}

&.html-markup-l {
  table {
    width: 100%;
    line-height: 120%;

    td,
    th {
      padding: 8px 16px;
    }
  }

  &.html-markup-dark-bg {
    table {
      font-size: 0.875rem;
      color: var(--text-color);
      border-spacing: 12px;

      border-collapse: separate;
      border: none;

      td,
      th {
        border: none;
      }

      th {
        padding: 0;
        font-size: 0.875rem;
      }

      td {
        padding: 14px;
        background-color: var(--bg-color);
        border-radius: 5px;
      }
    }
  }
}
