@use 'library' as core;

* {
  box-sizing: border-box;

  // iOS issue with blue outline on focus
  &:focus {
    outline: none;
  }
}

body {
  margin: 0;
  -webkit-text-size-adjust: none;
  position: relative;
}

.full-width {
  width: 100%;
}

.max-full-width {
  max-width: 100%;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-y {
  display: flex;
  align-items: center;
}

.rounded-xs {
  @include core.rounded-xs;
}

.rounded-s {
  @include core.rounded-s;
}

.rounded-l {
  @include core.rounded-l;
}

.rounded-circle {
  @include core.rounded-circle;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.k-display-none {
  display: none;
}

.hidden {
  display: none;
}

@each $key, $value in core.$breakpoints {
  .block-#{$key} {
    @include core.breakpoint($key) {
      display: block !important;
    }
  }

  .hidden-#{$key} {
    @include core.breakpoint($key) {
      display: none;
    }
  }
}

.scrollable-x {
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

@each $key, $value in core.$breakpoints {
  .scrollable-x-#{$key} {
    @include core.breakpoint($key) {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.scrollable-y {
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

@each $key, $value in core.$breakpoints {
  .scrollable-y-#{$key} {
    @include core.breakpoint($key) {
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.scroll-gradient-right {
  &::after {
    @include core.scroll-gradient-right;
  }
}

.scroll-gradient-bottom {
  &::after {
    @include core.scroll-gradient-bottom;
  }
}
